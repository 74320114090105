
export default {
  name: 'FooterPage',
  components: {},
  setup() {
    const onHandlePageWebSiteHat = () => {
      window.location.href = 'https://www.hat.co.jp/event/mirai1';
    };
    return {
      onHandlePageWebSiteHat,
    };
  },
};
