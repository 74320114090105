
import Header from '@/layouts/default/Header.vue';
import Footer from '@/layouts/default/Footer.vue';
import { ref, onMounted, onBeforeMount } from 'vue';

export default {
  name: 'HomeView',
  components: {
    Header,
    Footer,
  },
  setup() {
    const isCheckScroll = ref<boolean>(false);

    const handleScroll = () => {
      if (window.scrollY > 100) {
        isCheckScroll.value = true;
      } else {
        isCheckScroll.value = false;
      }
    };

    const onHandleGoTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    onMounted(() => {
      handleScroll();
      window.addEventListener('scroll', handleScroll);
    });

    onBeforeMount(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    return {
      isCheckScroll,
      onHandleGoTop,
    };
  },
};
