import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import View from '@/pages/front/View.vue';
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: () => '/maker',
    component: View,

    children: [
      {
        path: 'maker',
        name: 'StoreList',
        component: () => import(/* webpackChunkName: "StoreList" */ '@/pages/front/Stores.vue'),
      },
      {
        path: 'maker/:id',
        name: 'StoreDetail',
        component: () =>
          import(/* webpackChunkName: "StoreDetail" */ '@/pages/front/StoreDetail.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
