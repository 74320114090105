
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';

export default {
  name: 'HeaderPage',
  components: {},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const isCheckPageDetail = computed(() => (route.params.id ? true : false));
    const onHandlePageHome = () => {
      router.push('/maker');
    };
    return {
      isCheckPageDetail,
      onHandlePageHome,
    };
  },
};
